import * as ReactDom from 'react-dom/client';
import { FeatureAppManager, Logger } from '@feature-hub/core';

import {
  RenderFeatureAppOptions,
  hydrateFeatureApp,
  renderFeatureApp,
} from './feature-app-loader-interface';
import {
  getConfig,
  isFalconCms,
  parseFeatureAppAttributes,
} from './feature-app-parsing';

import { ContentStore } from '@oneaudi/content-service';
import { prepareWindow } from '../feature-hub/service-configuration';

export interface ProcessFeatureAppOptions {
  readonly contentStore?: ContentStore;
  readonly featureAppManager: FeatureAppManager;
  readonly iconBasePath?: string;
  readonly logger: Logger;
}

interface componentObject {
  componentInfo: {
    componentID: string;
    componentName: string;
    implementer?: number;
    version?: string;
  };
  category: {
    primaryCategory: string;
  };
  attributes: {
    implementer: number;
    moduleNumber: number;
    subNumber: number;
    version: string;
  };
}

const prepareComponentArray = function (): void {
  prepareWindow();
  if (typeof window.digitalData.component === 'undefined') {
    window.digitalData.component = [];
  }
};

function getFeatureAppImplementerForKnownFeatureApps(
  featureAppName: string,
): number {
  const defaultImplementerId = 0;
  const dbadImplementerId = 1;
  const s2ImplementerId = 2;

  if (
    featureAppName === 'audi-feature-app-basket' ||
    featureAppName === 'audi-feature-app-footer' ||
    featureAppName === 'audi-feature-app-footnote-engine' ||
    featureAppName === 'audi-feature-app-hello-world-bad' ||
    featureAppName === 'audi-feature-app-header' ||
    featureAppName === 'audi-feature-app-most-wanted-teaser' ||
    featureAppName === 'audi-most-wanted-teaser' ||
    featureAppName === 'audi-feature-app-vtp-carinfo'
  ) {
    return dbadImplementerId;
  }

  if (
    featureAppName === 'audi-feature-app-cct-compare' ||
    featureAppName === 'audi-feature-app-cct-teaser' ||
    featureAppName === 'audi-feature-app-hello-world' ||
    featureAppName === 'audi-feature-app-notifications' ||
    featureAppName === 'audi-feature-app-usermenu' ||
    featureAppName === 'audi-feature-app-visualizer'
  ) {
    return s2ImplementerId;
  }

  return defaultImplementerId;
}

function extractFeatureAppInfos(featureAppLocation: string): {
  featureAppName: string;
  featureAppImplementer: number;
  featureAppVersion: string;
} {
  const pathElems = featureAppLocation.split('/');
  let featureAppName = '';
  let pathElemIndex = 0;
  let featureAppNameIndex = 0;

  for (const pathElement of pathElems) {
    if (pathElement) {
      if (pathElement.indexOf('audi-') === 0) {
        featureAppName = pathElement;
        featureAppNameIndex = pathElemIndex;
        break;
      } else if (/one\.audi(\.com)?$/gi.test(pathElement)) {
        // retrieve the first element from the host name
        featureAppName = pathElement.split('.')[0];
        featureAppNameIndex = pathElemIndex;
        break;
      }
    }

    pathElemIndex += 1;
  }

  const featureAppVersion = pathElems[featureAppNameIndex + 1] || '';
  const featureAppImplementer =
    getFeatureAppImplementerForKnownFeatureApps(featureAppName);

  return {
    featureAppImplementer,
    featureAppName,
    featureAppVersion,
  };
}

function componentExistAlreadyInDataLayer(featureAppName: string): boolean {
  const componentArray = window.digitalData.component as Array<componentObject>;

  for (const component of componentArray) {
    if (component.componentInfo?.componentName === featureAppName) {
      return true;
    }
  }

  return false;
}

function featureAppIdExistAlreadyInDataLayer(featureAppId: string): boolean {
  const componentArray = window.digitalData.component as Array<componentObject>;

  for (const component of componentArray) {
    if (component.componentInfo?.componentID === featureAppId) {
      return true;
    }
  }

  return false;
}

function getHighestComponentModuleNumber(featureAppName: string): number {
  const componentArray = window.digitalData.component as Array<componentObject>;
  let highestModuleIndex = 0;

  for (const component of componentArray) {
    if (component.componentInfo?.componentName === featureAppName) {
      if (
        component.attributes &&
        component.attributes.moduleNumber > highestModuleIndex
      ) {
        highestModuleIndex = component.attributes.moduleNumber;
      }
    }
  }

  return highestModuleIndex;
}

function addToComponentDataLayer(
  featureAppId: string,
  featureAppName: string,
  featureAppImplementer: number,
  featureAppVersion: string,
): void {
  prepareComponentArray();

  let moduleNumber = 0;

  // do not update an existing definition to avoid overriding values set by the feature app
  if (featureAppId && featureAppIdExistAlreadyInDataLayer(featureAppId)) {
    return;
  }

  if (componentExistAlreadyInDataLayer(featureAppName)) {
    moduleNumber = getHighestComponentModuleNumber(featureAppName) + 1;
  }

  (window.digitalData.component as Array<componentObject>).push({
    attributes: {
      implementer: featureAppImplementer,
      moduleNumber: moduleNumber,
      subNumber: 0,
      version: featureAppVersion,
    },
    category: {
      primaryCategory: '',
    },
    componentInfo: {
      componentID: featureAppId,
      componentName: featureAppName,
      implementer: featureAppImplementer,
      version: featureAppVersion,
    },
  });
}

function processFeatureApp(
  featureApp: Element,
  options: ProcessFeatureAppOptions,
): ReactDom.Root | undefined {
  const { contentStore, featureAppManager, iconBasePath, logger } = options;

  const {
    baseUrl,
    callbacks,
    configAttribute,
    content,
    cssAttribute,
    featureAppId,
    serverSrc,
    src,
  } = parseFeatureAppAttributes(featureApp);

  if (src) {
    if (content && contentStore) {
      try {
        contentStore.setContent(featureAppId, JSON.parse(content));
      } catch (error) {
        logger.error(`content could not be parsed - ${error.message}`);
      }
    }

    const featureAppLocation = baseUrl ? baseUrl : src;
    const { featureAppName, featureAppImplementer, featureAppVersion } =
      extractFeatureAppInfos(featureAppLocation);
    addToComponentDataLayer(
      featureAppId,
      featureAppName,
      featureAppImplementer,
      featureAppVersion,
    );

    try {
      let css = [];
      try {
        css = cssAttribute ? JSON.parse(cssAttribute) : [];
      } catch (error) {
        logger.error(`css could not be parsed - ${error.message}`);
      }

      const isFalcon = isFalconCms(document.head);
      const config = getConfig(configAttribute, featureAppId, isFalcon, logger);

      if (callbacks) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        config.callbacks = (window as any)[callbacks];
      }

      const renderOptions: RenderFeatureAppOptions = {
        featureApp,
        featureAppAttributes: {
          baseUrl,
          callbacks,
          config,
          css,
          featureAppId,
          serverSrc,
          src,
        },
        featureAppManager,
        iconBasePath,
        logger,
      };

      return serverSrc
        ? hydrateFeatureApp(renderOptions)
        : renderFeatureApp(renderOptions);
    } catch (error) {
      error.message = `[${featureAppId}] ${error.message}`;
      logger.error(error);
    }
  } else {
    logger.warn('no src attribute found for ', featureAppId);
  }
}

export {
  processFeatureApp,
  extractFeatureAppInfos as __extractFeatureAppInfos,
  componentExistAlreadyInDataLayer as __componentExistAlreadyInDataLayer,
  featureAppIdExistAlreadyInDataLayer as __featureAppExistsAlreadyInDataLayer,
  getHighestComponentModuleNumber as __getHighestComponentModuleNumber,
};
